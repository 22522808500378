import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import RouteKey from "./constants";

function PrivateRoute({children, ...rest}) {
  const isLoggedIn = useSelector(state => state.authServices.isLoggedIn)
  return (
    <Route
      {...rest}
      render={({location}) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: RouteKey.Login,
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute
