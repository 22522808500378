import React from 'react'
import RouteKey from "./constants";

const SearchPage = React.lazy(() => import('../view/search/SearchPage'))
const ProfilePage = React.lazy(() => import('../view/profile/ProfilePage'))
const ContentPage = React.lazy(() => import('../view/contents/ContentPage'))

export const guestRoutes = [{path: '/', exact: true, name: 'Search', component: ProfilePage},]

export const endUserRoutes = [
  {path: '/', exact: true, name: 'Search', component: SearchPage},
  {
    path: RouteKey.Profile,
    exact: true,
    name: 'Profile',
    component: ProfilePage
  },
  {
    path: RouteKey.Content,
    exact: true,
    name: 'Content',
    component: ContentPage
  },
]
