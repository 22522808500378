import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import './App.css';
import './styles/style.scss'
import LoadingScreen from "./view/LoadingScreen";
import {useSelector} from "react-redux";
import {history} from "./store/store";
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from "./routes/PrivateRoute";
import MainLayout from "./pages/MainLayout";
import {ToastContainer} from "react-toastify";
import RouteKey from "./routes/constants";

const LoginPage = React.lazy(() => import('./view/login/LoginPage'));


const App = () => {
  const showGlobalIndicator = useSelector(state => state.appServices.showGlobalIndicator)

  return <React.Suspense fallback={<LoadingScreen/>}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={RouteKey.Login} name="Login Page" render={props => <LoginPage {...props} />}/>
        <Route exact path={RouteKey.ResetPassword} name="Reset Page" render={props => <LoginPage {...props} />}/>
        <PrivateRoute path={'/'}>
          <Route path="/" name="Home" render={props => <MainLayout {...props} />}/>
        </PrivateRoute>
      </Switch>
    </ConnectedRouter>
    {showGlobalIndicator && <LoadingScreen/>}
    <ToastContainer/>
  </React.Suspense>
}

export default App

