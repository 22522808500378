import {Image, Input, Layout} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHouse, faRightFromBracket, faSearch} from '@fortawesome/pro-regular-svg-icons'
import {faStar, faUser} from '@fortawesome/pro-solid-svg-icons'
import {useDispatch, useSelector} from 'react-redux'
import {authActions} from '../store/reducers/authServices'
import {updateFilter} from '../store/reducers/contentServices'
import {useState} from 'react'
import {Link, useHistory} from "react-router-dom";

const {Header} = Layout

const HeaderComponent = ({}) => {
  const dispatch = useDispatch()
  const navigate = useHistory()
  const filter = useSelector(state => state.contentServices.filter)
  const selectedItem = useSelector(state => state.contentServices.selectedItem)
  const {flaggedDocs} = useSelector(state => state.authServices)
  const [searchTerm, setSearchTerm] = useState(filter.searchTerm || '')
  const isEndUser = useSelector(state => state.authServices.isEndUser)

  const handleLogout = () => {
    dispatch(authActions.logout())
    navigate.push('/')
  }

  const handleStarredClick = () => {
    if (filter?.flaggedDocIds.length === 0) {
      dispatch(
        updateFilter({
          flaggedDocIds: flaggedDocs?.flaggedDocIds.split(';'),
        }),
      )
      navigate.push('/')
    } else {
      if (navigate.asPath === '/' || navigate.asPath.includes('/search')) {
        dispatch(
          updateFilter({
            flaggedDocIds: '',
          }),
        )
      }
      navigate.push('/')
    }
  }

  const handleSearchData = text => {
    dispatch(
      updateFilter({
        searchTerm: text,
      }),
    )
    // if (!navigate.pathname?.includes('search')) {
    //   navigate.push('/search')
    // }
  }

  return (
    <Header className="w-screen px-4 sm:px-10 bg-navy flex justify-between items-center h-16">
      {/* Logo */}
      <div className="hidden sm:flex">
        <Link to="/" passHref>
          <a className="flex flex-col w-36">
            <Image src={require('../assets/myBepozLogo.png')} height={40} width={188} layout="responsive" alt="" preview={false}/>
          </a>
        </Link>
      </div>

      {/* Search Bar */}
      <div className="flex flex-1 justify-center">
        {isEndUser && (
          <Input
            className="bg-aqua search rounded-lg mx-4 sm:mx-10 lg:w-1/2 border-0 focus:border-0"
            prefix={
              <FontAwesomeIcon
                onClick={() => handleSearchData(searchTerm)}
                icon={faSearch}
                color="white"
                size="lg"
                className="mr-1"
              />
            }
            placeholder="Type here to search"
            enterKeyHint="search"
            value={searchTerm}
            size="small"
            onChange={e => setSearchTerm(e.target.value)}
            onPressEnter={e => {
              handleSearchData(e.target.value)
            }}
          />
        )}
      </div>

      {/* Menu Icons */}
      <div className="flex">
        <FontAwesomeIcon
          icon={faHouse}
          size="2x"
          color="white"
          className="px-3 cursor-pointer"
          onClick={() => navigate.push('/')}
        />
        <FontAwesomeIcon
          icon={faStar}
          size="2x"
          color="white"
          className="hidden sm:block px-3 cursor-pointer"
          onClick={handleStarredClick}
        />
        <FontAwesomeIcon
          icon={faUser}
          size="2x"
          color="white"
          className="px-3 cursor-pointer"
          onClick={() => navigate.push('/profile')}
        />
        <FontAwesomeIcon
          icon={faRightFromBracket}
          size="2x"
          color="white"
          className="px-3 cursor-pointer"
          onClick={handleLogout}
        />
      </div>
    </Header>
  )
}

export default HeaderComponent
