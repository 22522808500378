import {getWithTimeout, postWithTimeout} from './networking'
import {API_URI, SUB_DOMAIN} from '../constants/constants'
import {
  deleteWithCheckingToken, getAccessToken, getWithCheckingToken, postWithCheckingToken, putWithCheckingToken,
} from './NetworkingAuth'
import {bodySearch} from '../view/search/constant/constant'

export function userLogin(data) {
  return postWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/AccountLogin`, {}, data)
}

export function userSignUp(data) {
  return postWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/AccountRegistration`, {}, data)
}

export function getTermsAndConditions() {
  return getWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/GetTermsAndConditions`)
}

export function forgotPassword(email) {
  return postWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/AccountForgotPassword?email=${email}`, {}, {},)
}

export function getRecentlyViewed(userId, pageSize = 20) {
  return getWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/uservieweddocs/GetByBepozId/${userId}/${pageSize}`, {},)
}

export function getLabels() {
  return getWithCheckingToken(`${SUB_DOMAIN.CONTENT}${API_URI}api/labels/getSimple`)
}

export function getFilterResources() {
  return getWithCheckingToken(`${SUB_DOMAIN.CONTENT}${API_URI}api/resources/getSimple`)
}

export function getLevels() {
  return getWithCheckingToken(`${SUB_DOMAIN.CONTENT}${API_URI}api/levels/getSimple`)
}

export function searchData(data) {
  return postWithCheckingToken(`${SUB_DOMAIN.SEARCH}${API_URI}api/query`, {}, data)
}

export function userViewDocs(bepozId, docId) {
  return postWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/uservieweddocs`, {}, {
    bepozId, docId, id: 0,
  },)
}

// export function userViewVideo(bepozId, docId) {
//   return
// }

export function getResources() {
  return getWithCheckingToken(`${SUB_DOMAIN.SEARCH}${API_URI}api/query/GetResources`)
}

export function getChannels() {
  return getWithCheckingToken(`${SUB_DOMAIN.SEARCH}${API_URI}api/query/GetChannels`)
}

export function getResourceDocs(resourceId, versionId) {
  return getWithCheckingToken(`${SUB_DOMAIN.SEARCH}${API_URI}api/query/GetResourceDocs/${resourceId}/${versionId}`,)
}

export function getPublicInfo(media) {
  return postWithTimeout(`${SUB_DOMAIN.MEDIA}${API_URI}api/libraryvideos/GetPublishInfo`, {}, [media])
}

export function getDataRecentlyView(docIds) {
  return postWithCheckingToken(`${SUB_DOMAIN.SEARCH}${API_URI}api/query/RunMetaDataQuery`, {}, {
    ...bodySearch, resourceIds: [0], docIds, pagination: {pageNo: 1, pageSize: 20},
  },)
}

export function uploadAvatar(bepozId, image) {
  let formdata = new FormData()
  formdata.append('file', image)
  return fetch(`${SUB_DOMAIN.USER}${API_URI}api/profiles/uploadProfileImage?bepozId=${bepozId}`, {
    method: 'post', headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }, body: formdata,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok, ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function uploadCoverImage(bepozId, image) {
  let formdata = new FormData()
  formdata.append('file', image)
  return fetch(`${SUB_DOMAIN.USER}${API_URI}api/profiles/uploadProfileHeaderImage?bepozId=${bepozId}`, {
    method: 'post', headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    }, body: formdata,
  })
    .then(response => {
      return response.json().then(data => {
        return {
          ok: response.ok, ...data,
        }
      })
    })
    .catch(err => {
      return err
    })
}

export function updateUserInfo(bepozId, data) {
  return putWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/Profiles/UpdateUserProfile/${bepozId}`, {}, data,)
}

export function getUserInfo(bepozId) {
  return getWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/profiles/getUserPreferences/${bepozId}`, {})
}

//FLAGS
export function createUserFlaggedDocs(data) {
  return postWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/userflags/`, {}, data)
}

export function updateUserFlaggedDocs(bepozId, data) {
  return putWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/userflags/${bepozId}`, {}, data)
}

export function getUserFlaggedDocs(flagId) {
  return getWithTimeout(`${SUB_DOMAIN.USER}${API_URI}api/userflags/getbybepozid/${flagId}`)
}

//VENUES
export function getUserVenues(bepozId) {
  return getWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}/api/approvals/getApprovalRequests/${bepozId}`)
}

export function approveOrRevokeVenue(bepozId, data) {
  return putWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/approvals/setApproval/${bepozId}`, {}, data)
}

export function getEmailByUserId(userId) {
  return getWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/GetEmail?userId=${userId}`)
}

export function resetPassword(userId, email, password, code) {
  return postWithTimeout(`${SUB_DOMAIN.AUTH}${API_URI}auth/Account/AccountResetPassword`, {}, {
    userId, email, password, code
  })
}
// ratings = 1 | 5
export function userRating(id, bepozId, docId, ratings) {
  if (!id) {
    return postWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/userratings`, {}, {
      bepozId,
      docId,
      ratings
    })
  } else {
    return putWithCheckingToken(`${SUB_DOMAIN.USER}${API_URI}api/userratings/${id}`, {}, {
      id,
      bepozId,
      docId,
      ratings
    })
  }
}

export function getUserRatingDocs(bepozId) {
  return getWithTimeout(`${SUB_DOMAIN.USER}${API_URI}api/userratings/getbybepozid/${bepozId}`)
}
